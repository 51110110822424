import { graphql } from '@/gql'

const LIST_CONFIGURATION_ITEMS = graphql(`
  query ListCI(
    $filters: ConfigurationItemFilterInput
    $pagination: PaginationInput
  ) {
    operations {
      configurationItems(filters: $filters, pagination: $pagination) {
        result {
          cpuCount
          hostName
          installStatus
          ipAddress
          name
          os
          osVersion
          ram
          shortDescription
          sysClassName
          sysId
          uLogicalTag
          uPatchDaySchedule
          uPatchMonthSchedule
          uPatchTime
          uPatchWeekSchedule
          uPatchingDisabledReason
          uPatchingEnabled
          uSupportLevel
          uVmTechnology
        }
        totalCount
        metadata {
          conversationId
          message
          statusCode
          statusText
        }
      }
    }
  }
`)

export { LIST_CONFIGURATION_ITEMS }
