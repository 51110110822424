import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useApolloClient } from '@vue/apollo-composable'
import {
  LIST_ACCOUNT_WITH_OWNER_AND_MANAGER,
  LIST_USER_ACCOUNTS,
} from '@/graphql/query/accounts'
import { Account, Maybe } from '@/gql/graphql'
import { Nullable } from '@/types/base'
import { useLoading } from '@/store/useLoading'

const ACCOUNT_NUMBER_REGEX = new RegExp('^ACC-[0-9]{10}$')

export const useAccounts = defineStore(
  'accounts',
  () => {
    const accountInformation = ref<Account>()
    const userAccounts = ref<Account[] | undefined>()
    const currentAccount = ref<string>()
    const allowedAccounts = ref<string[]>([])

    function setAllowedAccounts(groups: string[]): void {
      allowedAccounts.value = groups.filter((group) =>
        group.match(ACCOUNT_NUMBER_REGEX)
      )
      if (allowedAccounts.value && !currentAccount.value) {
        currentAccount.value = allowedAccounts.value[0]
      }
    }

    function clearAccounts(): void {
      localStorage.removeItem('accounts')
    }

    function getCurrentAccountName(): Maybe<Nullable<string>> {
      const accounts = userAccounts?.value?.filter(
        (x) => x.accountNumber == currentAccount.value
      )

      return accounts && accounts.length > 0 ? accounts[0].accountName : ''
    }

    async function loadUserAccounts(): Promise<void> {
      if (userAccounts.value) {
        const unMatchedAccounts = userAccounts.value.filter(
          (item) => allowedAccounts?.value.indexOf(item.accountNumber ?? '') < 0
        )
        if (unMatchedAccounts.length > 0) {
          clearAccounts()
        }
      }

      if (!userAccounts.value) {
        const client = useApolloClient()
        const result = await client.client.query({ query: LIST_USER_ACCOUNTS })
        userAccounts.value = (result.data?.user?.accounts?.result ??
          []) as Account[]
      }
    }

    async function loadAccountInformation(): Promise<void> {
      if (
        !accountInformation?.value ||
        accountInformation.value?.accountNumber !== currentAccount.value
      ) {
        const loading = useLoading()
        const client = useApolloClient()
        loading.loadingCount++
        const result = await client.client.query({
          query: LIST_ACCOUNT_WITH_OWNER_AND_MANAGER,
        })
        if (
          result.data?.customers?.accounts?.result &&
          result.data.customers.accounts.result.length > 0
        ) {
          accountInformation.value = result.data.customers.accounts
            .result[0] as Account
          loading.loadingCount--
        }
      }
    }

    function setCurrentAccount(accountNumber: string): void {
      currentAccount.value = accountNumber
    }

    return {
      accountInformation,
      allowedAccounts,
      currentAccount,
      userAccounts,
      getCurrentAccountName,
      setAllowedAccounts,
      clearAccounts,
      loadUserAccounts,
      loadAccountInformation,
      setCurrentAccount,
    }
  },
  {
    persist: true,
  }
)
