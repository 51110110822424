import { RouteRecordRaw } from 'vue-router'
import { AUTH_MODULE } from '@/constants/roles'

const configurationItemRoutes: RouteRecordRaw = {
  name: 'Configuration Items',
  path: 'assets/configuration-items',
  redirect: { name: 'Configuration Item List' },
  component: () => import('@/view/partials/AppLayout.vue'),
  children: [
    {
      name: 'Configuration Item List',
      path: '',
      component: () => import('./ConfigurationItems.vue'),
      meta: {
        title: 'My Sentia | Configuration Items',
        requiresAuth: true,
        roles: AUTH_MODULE.CONFIGURATION_ITEMS,
      },
    },
    {
      name: 'Configuration Item Detail',
      path: ':sys_id',
      component: () => import('./sys-id/ConfigurationItemLayout.vue'),
      meta: {
        title: 'My Sentia | Configuration Item Detail',
        requiresAuth: true,
        roles: AUTH_MODULE.CONFIGURATION_ITEMS,
      },
    },
  ],
}

export { configurationItemRoutes }
