import { graphql } from '@/gql'

const LIST_ACCOUNT_WITH_OWNER_AND_MANAGER = graphql(`
  query listAccountWithOwnerAndManager($filters: AccountFilterInput!) {
    customers {
      accounts(filters: $filters) {
        totalCount
        result {
          accountId
          accountNumber
          accountName
          invoiceDetails {
            address {
              city
              country
              postalCode
              street
            }
            emailAddress
            vatNumber
          }
          organizationalUnit
          owner {
            result {
              emailAddress
              firstName
              lastName
              mobilePhone
            }
          }
          serviceManager {
            result {
              emailAddress
              firstName
              lastName
              mobilePhone
            }
          }
          telephone
        }
      }
    }
  }
`)

const LIST_ACCOUNT_WITH_SERVICENOW_ACCOUNT = graphql(`
  query listAccountWithServiceNowAccount($filters: AccountFilterInput!) {
    customers {
      accounts(filters: $filters) {
        result {
          accountNumber
          serviceNowAccount {
            result {
              sysId
              uSupportInformation
            }
          }
        }
        totalCount
      }
    }
  }
`)

const LIST_USER_ACCOUNTS = graphql(`
  query listUserAccount {
    user {
      accounts {
        result {
          accountNumber
          accountName
        }
        metadata {
          conversationId
          statusCode
          statusText
        }
      }
    }
  }
`)

export {
  LIST_ACCOUNT_WITH_OWNER_AND_MANAGER,
  LIST_USER_ACCOUNTS,
  LIST_ACCOUNT_WITH_SERVICENOW_ACCOUNT,
}
