<template>
  <div>
    <!-- Sidebar -->
    <div
      id="sidebar"
      ref="sidebar"
      class="flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-16 lg:sidebar-expanded:!w-64 shrink-0 bg-sblack-500 py-4 px-3 transition-all duration-300 ease-in-out"
      :class="sidebarOpen ? 'translate-x-0' : '-translate-x-64'"
    >
      <!-- Sidebar header -->
      <div class="flex justify-between mb-10 pr-3 sm:px-2">
        <!-- Close button -->
        <button
          ref="trigger"
          class="lg:hidden text-gray-500 hover:text-gray-400"
          aria-controls="sidebar"
          :aria-expanded="sidebarOpen"
          @click.stop="$emit('close-sidebar')"
        >
          <span class="sr-only">Close sidebar</span>
          <svg
            class="w-6 h-6 fill-current"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"
            />
          </svg>
        </button>
        <!-- Logo -->
        <router-link class="block" to="/home">
          <div class="h-10">
            <img
              v-if="sidebarExpanded"
              src="/media/logos/logo-sentia.svg"
              alt="Logo Sentia"
            />
            <img
              v-else-if="sidebarOpen || sidebarExpanded"
              src="/media/logos/logo-sentia.svg"
              alt="Logo Sentia"
            />
            <img
              v-else
              width="32"
              src="/media/logos/logo-sentia-icon.svg"
              alt="Logo Sentia"
            />
          </div>
        </router-link>
      </div>

      <!-- Links -->
      <div class="space-y-8">
        <!-- Pages group -->
        <div>
          <ul class="mt-3 px-2">
            <!-- home -->
            <template
              v-for="(navigationItem, index) in navigationItems"
              :key="index"
            >
              <SSidebarMultiLevel
                :navigation-item="navigationItem"
              ></SSidebarMultiLevel>
            </template>
          </ul>
        </div>
      </div>

      <!-- Expand / collapse button -->
      <div class="pt-3 hidden lg:inline-flex justify-end mt-auto">
        <div class="px-3 py-2">
          <button @click.prevent="sidebarExpanded = !sidebarExpanded">
            <span class="sr-only">Expand / collapse sidebar</span>
            <ChevronDoubleRightIcon
              class="w-6 h-6 sidebar-expanded:rotate-180 fill-gray-400"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  // Imports
  import { ref } from 'vue'
  import { ChevronDoubleRightIcon } from '@heroicons/vue/solid'
  import { AUTH_MODULE } from '@/constants/roles'
  import SSidebarMultiLevel from '@/view/partials/layout/SSidebarMultiLevel.vue'
  import { storeToRefs } from 'pinia'
  import { useSidebar } from '@/store/useSidebar'
  import { NavigationItem } from '@/types/base'

  // Refs
  const trigger = ref(null)
  const sidebar = ref(null)
  const sidebarStore = useSidebar()
  const { sidebarExpanded } = storeToRefs(sidebarStore)

  // Props
  defineProps<{
    sidebarOpen: boolean
  }>()

  // Emits
  defineEmits<{
    (event: 'close-sidebar'): void
  }>()

  const homeNavigation: NavigationItem = {
    icon: {
      name: 'HomeIcon',
      solid: false,
    },
    path: '/home',
    name: 'Home',
    subMenu: false,
    roles: AUTH_MODULE.HOME,
  }

  const assetsNavigation: NavigationItem = {
    icon: {
      name: 'CubeIcon',
      solid: false,
    },
    path: '',
    name: 'Assets',
    subMenu: true,
    children: [
      {
        path: '/assets/configuration-items',
        name: 'Configuration Items',
        roles: AUTH_MODULE.CONFIGURATION_ITEMS,
      },
    ],
    roles: AUTH_MODULE.CONFIGURATION_ITEMS,
  }

  const companyInformationNavigation: NavigationItem = {
    icon: {
      name: 'UserIcon',
      solid: false,
    },
    path: '',
    name: 'Company Information',
    subMenu: true,
    children: [
      {
        path: '/company-information/account',
        name: 'Account',
        roles: AUTH_MODULE.ACCOUNTS,
      },
      {
        path: '/company-information/contacts',
        name: 'Contacts',
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    ],
    roles: [...AUTH_MODULE.ACCOUNTS, ...AUTH_MODULE.CONTACTS],
  }

  const myInsightsNavigation: NavigationItem = {
    icon: {
      name: 'SparklesIcon',
      solid: false,
    },
    path: '',
    name: 'Insights',
    subMenu: true,
    children: [
      {
        path: '/insights/cost',
        name: 'My Cost Insights',
        roles: AUTH_MODULE.REPORTS,
      },
      {
        path: '/insights/license-report',
        name: 'My Licenses',
        roles: AUTH_MODULE.REPORTS,
      },
    ],
    roles: AUTH_MODULE.REPORTS,
  }

  const reportsNavigation: NavigationItem = {
    icon: {
      name: 'TicketIcon',
      solid: false,
    },
    path: '',
    name: 'Reports',
    subMenu: true,
    children: [
      {
        groupPath: '/reports',
        path: '/reports/cases',
        name: 'Cases',
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
      {
        groupPath: '/reports',
        path: '/reports/changes',
        name: 'Changes',
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
      {
        groupPath: '/reports',
        path: '',
        name: 'Incidents',
        subMenu: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
        children: [
          {
            groupPath: '/reports/incidents',
            path: '/reports/incidents',
            name: 'Incidents',
            roles: AUTH_MODULE.NOT_PERMITTED,
          },
          {
            groupPath: '/reports/security-incidents',
            path: '/reports/security-incidents',
            name: 'Security Incidents',
            roles: AUTH_MODULE.NOT_PERMITTED,
          },
          {
            groupPath: '/reports/service-requests',
            path: '/reports/service-requests',
            name: 'Service Requests',
            roles: AUTH_MODULE.NOT_PERMITTED,
          },
        ],
      },
      {
        path: '/reports/problems',
        name: 'Problems',
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    ],
    roles: [
      ...AUTH_MODULE.NOT_PERMITTED,
      ...AUTH_MODULE.NOT_PERMITTED,
      ...AUTH_MODULE.NOT_PERMITTED,
      ...AUTH_MODULE.NOT_PERMITTED,
    ],
  }

  const navigationItems: NavigationItem[] = [
    homeNavigation,
    assetsNavigation,
    companyInformationNavigation,
    myInsightsNavigation,
    reportsNavigation,
  ]
</script>
