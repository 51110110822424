type AuthModuleType = {
  ACCOUNTS: string[]
  CASES: string[]
  CASES_UPDATE: string[]
  CHANGES: string[]
  CONFIGURATION_ITEMS: string[]
  CONTACTS: string[]
  HOME: string[]
  INCIDENTS: string[]
  NOT_PERMITTED: string[]
  NRQL: string[]
  PROBLEMS: string[]
  REPORTS: string[]
  SERVICENOW_ACCOUNTS: string[]
  SERVICENOW_ACCOUNTS_UPDATE: string[]
}

const AUTH_MODULE: AuthModuleType = {
  ACCOUNTS: ['role-sg-my-query_dynamics365_accounts'],
  CASES: ['role-sg-my-query_servicenow_cases'],
  CASES_UPDATE: ['role-sg-my-mutation_servicenow_cases'],
  CHANGES: ['role-sg-my-query_servicenow_cases'],
  CONFIGURATION_ITEMS: ['role-sg-my-query_servicenow_configuration_items'],
  CONTACTS: ['role-sg-my-query_dynamics365_contacts'],
  HOME: ['role-sg-my-query_dynamics365_accounts'],
  INCIDENTS: ['role-sg-my-query_servicenow_cases'],
  NOT_PERMITTED: [],
  NRQL: ['role-sg-my-query_newrelic_nrql'],
  PROBLEMS: ['role-sg-my-query_servicenow_cases'],
  REPORTS: ['role-sg-my-query_power_bi_reports'],
  SERVICENOW_ACCOUNTS: ['role-sg-my-query_servicenow_accounts'],
  SERVICENOW_ACCOUNTS_UPDATE: ['role-sg-my-mutation_servicenow_accounts'],
}

export { AUTH_MODULE }
