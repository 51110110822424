import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { Notification } from '@/components/SNotification/types.d'
import { SentiaColors } from '@/types/sentiaStyle.d'
import { Metadata } from '@/gql/graphql'

export const useNotificationStore = defineStore('notificationStore', () => {
  const defaultNotification = reactive<Notification>({
    id: `${Date.now()}`,
    message: '',
    color: 'success',
    dismiss: {
      automatically: true,
      manually: true,
    },
    duration: 4000,
  })

  const notifications = reactive({} as Record<string, Notification>)

  const showSuccess = (message: string) => {
    setNotification(message, 'success')
  }

  const showError = (message: string) => {
    setNotification(message, 'danger')
  }

  const showMutationMessage = (metadata: Metadata, message?: string) => {
    if (metadata.statusCode === 200) {
      if (message) {
        setNotification(message, 'success')
      } else {
        setNotification('Changes were successfully saved', 'success')
      }
    } else {
      setNotification(metadata.message, 'danger')
    }
  }

  const setNotification = (message: string, color: SentiaColors) => {
    const notification = reactive<Notification>({
      id: `${Date.now()}`,
      message: message,
      color: color,
    })

    const fullNotification = JSON.parse(
      JSON.stringify({
        ...defaultNotification,
        ...notification,
      })
    )

    notifications[fullNotification.id] = fullNotification
  }

  const unsetNotification = (idNotification: string) => {
    delete notifications[idNotification]
  }

  return {
    notifications,
    showSuccess,
    showError,
    showMutationMessage,
    unsetNotification,
  }
})
