const COUNTRY_ENUM: { [index: string]: { [index: string]: string } } = {
  BELGIUM: {
    CODE: 'BE',
    FLAG: 'media/svg/flags/165-belgium.svg',
  },
  DENMARK: {
    CODE: 'DK',
    FLAG: 'media/svg/flags/174-denmark.svg',
  },
  NETHERLANDS: {
    CODE: 'NL',
    FLAG: 'media/svg/flags/237-netherlands.svg',
  },
  USA: {
    CODE: 'US',
    FLAG: 'media/svg/flags/226-united-states.svg',
  },
}

const ORGANIZATIONAL_UNIT_ENUM: {
  [index: string]: { [index: string]: string }
} = {
  'Sentia BE': {
    HOME_PHONE_NUMBER: '+32 (0)9 274 29 29',
    INSIGHTS_EMAIL: 'administration.be@sentia.com',
    INSIGHTS_PHONE_NUMBER: '+32 (0)9 274 29 00',
  },
  'Sentia DK': {
    HOME_PHONE_NUMBER: '+45 33 36 63 00',
    INSIGHTS_EMAIL: 'bogholderi@sentia.dk',
    INSIGHTS_PHONE_NUMBER: '+45 33 36 63 00',
  },
  'Sentia NL': {
    HOME_PHONE_NUMBER: '+31 (0)88 42 42 200',
    INSIGHTS_EMAIL: 'customerservice@sentia.com',
    INSIGHTS_PHONE_NUMBER: '+31 (0)88 42 42 281',
  },
}

export { COUNTRY_ENUM, ORGANIZATIONAL_UNIT_ENUM }
