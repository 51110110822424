import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { LIST_CONFIGURATION_ITEMS } from '@/graphql/query/configuration_items'
import { ConfigurationItem } from '@/gql/graphql'
import { Nullable } from '@/types/base'
import { Option } from '@/components/SSelect/types'
import { useLoading } from '@/store/useLoading'

export interface FilterForm {
  selectedTechnology: Nullable<Option>
  selectedOS: Nullable<Option>
  selectedInstallStatus: Nullable<Option>
  hostname: string
  name: string
}

export const useConfigurationItem = defineStore(
  'configurationItem',
  () => {
    const configurationItems: Record<string, ConfigurationItem> = {}
    const currentConfigurationItem = ref<ConfigurationItem>({})
    const currentSysId = ref()
    const filters = reactive<FilterForm>({
      selectedTechnology: undefined,
      selectedOS: undefined,
      selectedInstallStatus: undefined,
      hostname: '' as string,
      name: '' as string,
    })

    function clearConfigurationItem(): void {
      localStorage.removeItem('configurationItem')
    }

    function setSysId(sysId: string): void {
      if (sysId in configurationItems) {
        currentConfigurationItem.value = configurationItems[sysId]
      } else {
        currentSysId.value = sysId
        loadConfigurationItem()
      }
    }

    function loadConfigurationItem(): void {
      const variables = ref({
        filters: { sysId: { value: currentSysId.value } },
        pagination: { offset: 0, limit: 1 },
      })

      const loading = useLoading()
      loading.loadingCount++
      const { onResult } = useQuery(LIST_CONFIGURATION_ITEMS, variables)

      onResult((data) => {
        const query = data.data
        const result = query.operations?.configurationItems?.result
        currentConfigurationItem.value = Array.isArray(result)
          ? (result[0] as ConfigurationItem)
          : {}
        configurationItems[currentSysId.value] = currentConfigurationItem.value
        loading.loadingCount--
      })
    }

    return {
      setSysId,
      clearConfigurationItem,
      currentConfigurationItem,
      currentSysId,
      filters,
    }
  },
  {
    persist: {
      paths: ['filters'],
    },
  }
)
