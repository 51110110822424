import { RouteRecordRaw } from 'vue-router'
import { AUTH_MODULE } from '@/constants/roles'

const changesRoutes: RouteRecordRaw = {
  name: 'Reports Changes',
  path: 'changes',
  redirect: { name: 'Open Changes' },
  component: () => import('./ChangesLayout.vue'),
  children: [
    {
      name: 'Closed Changes',
      path: 'closed',
      component: () => import('./ClosedChanges.vue'),
      meta: {
        title: 'My Sentia | Closed Changes',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
    {
      name: 'Created Changes',
      path: 'created',
      component: () => import('./CreatedChanges.vue'),
      meta: {
        title: 'My Sentia | Created Changes',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
    {
      name: 'Open Changes',
      path: 'open',
      component: () => import('./OpenChanges.vue'),
      meta: {
        title: 'My Sentia | Open Changes',
        requiresAuth: true,
        roles: AUTH_MODULE.NOT_PERMITTED,
      },
    },
  ],
}

export { changesRoutes }
