export function useSentiaStyle() {
  const sizes: { [index: string]: string } = {
    xs: 'h-6 w-6',
    sm: 'h-8 w-8',
    md: 'h-10 w-10',
    lg: 'h-12 w-12',
    xl: 'h-14 w-14',
  }

  const shapes: { [index: string]: string } = {
    'rounded-none': 'rounded-none',
    rounded: 'rounded',
    'rounded-full': 'rounded-full',
  }

  const colors: { [index: string]: string } = {
    primary: 'text-sgold-500',
    danger: 'text-sred-500',
    success: 'text-sgreen-500 ',
    warning: 'text-sgold-500',
    info: 'text-sky-500',
    dark: 'text-sblack-200',
  }

  const backgroundColors: { [index: string]: string } = {
    default: 'bg-sgold-500',
    primary: 'bg-sgold-500',
    danger: 'bg-sred-500',
    success: 'bg-sgreen-500 ',
    warning: 'bg-sgold-500',
    info: 'bg-sky-500',
    dark: 'bg-sblack-500',
  }

  const backgroundColorsHover: { [index: string]: string } = {
    primary: 'hover:bg-sgold-400',
    danger: 'hover:bg-sred-400',
    success: 'hover:bg-sgreen-400',
    warning: 'hover:bg-sgold-400',
    info: 'hover:bg-sky-400',
    dark: 'hover:bg-sblack-400',
  }

  const backgroundColorsActive: { [index: string]: string } = {
    primary: 'active:bg-sgold-600',
    danger: 'active:bg-sred-600',
    success: 'active:bg-sgreen-600',
    warning: 'active:bg-sgold-600',
    info: 'active:bg-sky-600',
    dark: 'active:bg-sblack-600',
  }

  const outlineBackgroundColors: { [index: string]: string } = {
    primary: 'bg-sgold-100',
    danger: 'bg-sred-100',
    success: 'bg-sgreen-100',
    warning: 'bg-sgold-100',
    info: 'bg-sky-100',
    dark: 'bg-sblack-100',
  }

  const outlineBackgroundColorsHover: { [index: string]: string } = {
    primary: 'hover:bg-sgold-50',
    danger: 'hover:bg-sred-50',
    success: 'hover:bg-sgreen-50',
    warning: 'hover:bg-sgold-50',
    info: 'hover:bg-sky-50',
    dark: 'hover:bg-sblack-50',
  }

  const outlineBackgroundColorsActive: { [index: string]: string } = {
    primary: 'active:bg-sgold-200',
    danger: 'active:bg-sred-100',
    success: 'active:bg-sgreen-100',
    warning: 'active:bg-sgold-100',
    info: 'active:bg-sky-100',
    dark: 'active:bg-sblack-100',
  }

  const borderColors: { [index: string]: string } = {
    primary: 'border-sgold-500',
    danger: 'border-sred-500',
    success: 'border-sgreen-500',
    warning: 'border-sgold-500',
    info: 'border-sky-500',
    dark: 'border-sblack-500',
  }

  const padding: { [index: string]: string } = {
    xs: 'px-2 py-0',
    sm: 'px-3 py-1.5',
    md: 'px-4 py-2',
    lg: 'px-7 py-2.5',
    xl: 'px-9 py-3',
  }

  const inputPadding: { [index: string]: string } = {
    xs: 'px-1 py-0',
    sm: 'px-2 py-1',
    md: 'px-3 py-2',
    lg: 'px-4 py-3',
    xl: 'px-5 py-4',
  }

  const inputPaddingY: { [index: string]: string } = {
    xs: 'py-0',
    sm: 'py-1',
    md: 'py-2',
    lg: 'py-3',
    xl: 'py-4',
  }

  const errorMessageInputPadding: { [index: string]: string } = {
    xs: 'px-1 py-0',
    sm: 'px-2 py-0.5',
    md: 'px-3 py-0.5',
    lg: 'px-4 py-1',
    xl: 'px-5 py-1',
  }

  const inputBorderColor: { [index: string]: string } = {
    default: 'border-sgray-800',
  }

  const fontSizes: { [index: string]: string } = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
  }

  const topSize: { [index: string]: string } = {
    xs: 'peer-placeholder-shown:top-0 left-1',
    sm: 'peer-placeholder-shown:top-1 left-2',
    md: 'peer-placeholder-shown:top-2 left-3',
    lg: 'peer-placeholder-shown:top-3 left-4',
    xl: 'peer-placeholder-shown:top-4 left-5',
  }

  return {
    sizes,
    shapes,
    colors,
    borderColors,
    backgroundColors,
    backgroundColorsHover,
    backgroundColorsActive,
    outlineBackgroundColors,
    outlineBackgroundColorsHover,
    outlineBackgroundColorsActive,
    padding,
    inputPadding,
    inputPaddingY,
    errorMessageInputPadding,
    fontSizes,
    topSize,
    inputBorderColor,
  }
}
