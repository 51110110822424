import { RouteRecordRaw } from 'vue-router'
import { AUTH_MODULE } from '@/constants/roles'

const problemsRoutes: RouteRecordRaw = {
  name: 'Reports Problems',
  path: 'problems',
  redirect: { name: 'Open Problems' },
  component: () => import('./ProblemsLayout.vue'),
  children: [
    {
      name: 'Closed Problems',
      path: 'closed',
      component: () => import('./ClosedProblems.vue'),
      meta: {
        title: 'My Sentia | Closed Problems',
        requiresAuth: true,
        roles: AUTH_MODULE.PROBLEMS,
      },
    },
    {
      name: 'Created Problems',
      path: 'created',
      component: () => import('./CreatedProblems.vue'),
      meta: {
        title: 'My Sentia | Created Problems',
        requiresAuth: true,
        roles: AUTH_MODULE.PROBLEMS,
      },
    },
    {
      name: 'Open Problems',
      path: 'open',
      component: () => import('./OpenProblems.vue'),
      meta: {
        title: 'My Sentia | Open Problems',
        requiresAuth: true,
        roles: AUTH_MODULE.PROBLEMS,
      },
    },
  ],
}

export { problemsRoutes }
