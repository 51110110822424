/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  mutation serviceNowAccountUpdate($input: ServiceNowAccountInput!) {\n    serviceNowAccountUpdate(input: $input) {\n      metadata {\n        conversationId\n        message\n        statusCode\n        statusText\n      }\n      result {\n        sysId\n      }\n    }\n  }\n": types.ServiceNowAccountUpdateDocument,
    "\n  mutation serviceNowCaseCreate($input: CaseCreateInput!) {\n    serviceNowCaseCreate(input: $input) {\n      metadata {\n        conversationId\n        message\n        statusCode\n        statusText\n      }\n      result {\n        sysId\n      }\n    }\n  }\n": types.ServiceNowCaseCreateDocument,
    "\n  mutation serviceNowContactCaseCreate($input: ContactCaseCreateInput!) {\n    serviceNowContactCaseCreate(input: $input) {\n      metadata {\n        conversationId\n        message\n        statusCode\n        statusText\n      }\n      result {\n        sysId\n      }\n    }\n  }\n": types.ServiceNowContactCaseCreateDocument,
    "\n  query listAccountWithOwnerAndManager($filters: AccountFilterInput!) {\n    customers {\n      accounts(filters: $filters) {\n        totalCount\n        result {\n          accountId\n          accountNumber\n          accountName\n          invoiceDetails {\n            address {\n              city\n              country\n              postalCode\n              street\n            }\n            emailAddress\n            vatNumber\n          }\n          organizationalUnit\n          owner {\n            result {\n              emailAddress\n              firstName\n              lastName\n              mobilePhone\n            }\n          }\n          serviceManager {\n            result {\n              emailAddress\n              firstName\n              lastName\n              mobilePhone\n            }\n          }\n          telephone\n        }\n      }\n    }\n  }\n": types.ListAccountWithOwnerAndManagerDocument,
    "\n  query listAccountWithServiceNowAccount($filters: AccountFilterInput!) {\n    customers {\n      accounts(filters: $filters) {\n        result {\n          accountNumber\n          serviceNowAccount {\n            result {\n              sysId\n              uSupportInformation\n            }\n          }\n        }\n        totalCount\n      }\n    }\n  }\n": types.ListAccountWithServiceNowAccountDocument,
    "\n  query listUserAccount {\n    user {\n      accounts {\n        result {\n          accountNumber\n          accountName\n        }\n        metadata {\n          conversationId\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n": types.ListUserAccountDocument,
    "\n  query ListCase(\n    $pagination: PaginationInput\n    $filters: CaseFilterInput\n    $sorting: [CaseSortingInput]\n  ) {\n    operations {\n      cases(filters: $filters, pagination: $pagination, sorting: $sorting) {\n        totalCount\n        result {\n          comments\n          number\n          description\n          priority\n          state\n          subject\n          sysCreatedBy\n          sysCreatedOn\n          sysUpdatedBy\n          sysUpdatedOn\n          workNotes\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n": types.ListCaseDocument,
    "\n  query ListCaseByCI(\n    $pagination: PaginationInput\n    $filters: ConfigurationItemFilterInput\n    $sorting: [CaseSortingInput]\n  ) {\n    operations {\n      configurationItems(filters: $filters) {\n        totalCount\n        result {\n          cases(pagination: $pagination, sorting: $sorting) {\n            result {\n              comments\n              number\n              description\n              state\n              subject\n              sysCreatedBy\n              sysCreatedOn\n              workNotes\n            }\n            totalCount\n          }\n          name\n        }\n      }\n    }\n  }\n": types.ListCaseByCiDocument,
    "\n  query ListChanges(\n    $pagination: PaginationInput\n    $filters: ChangeFilterInput\n    $sorting: [ChangeSortingInput]\n  ) {\n    operations {\n      changes(filters: $filters, pagination: $pagination, sorting: $sorting) {\n        totalCount\n        result {\n          number\n          description\n          impact\n          priority\n          state\n          subject\n          sysCreatedBy\n          sysCreatedOn\n          sysId\n          sysUpdatedBy\n          sysUpdatedOn\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n": types.ListChangesDocument,
    "\n  query ListCI(\n    $filters: ConfigurationItemFilterInput\n    $pagination: PaginationInput\n  ) {\n    operations {\n      configurationItems(filters: $filters, pagination: $pagination) {\n        result {\n          cpuCount\n          hostName\n          installStatus\n          ipAddress\n          name\n          os\n          osVersion\n          ram\n          shortDescription\n          sysClassName\n          sysId\n          uLogicalTag\n          uPatchDaySchedule\n          uPatchMonthSchedule\n          uPatchTime\n          uPatchWeekSchedule\n          uPatchingDisabledReason\n          uPatchingEnabled\n          uSupportLevel\n          uVmTechnology\n        }\n        totalCount\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n": types.ListCiDocument,
    "\n  query ListContacts(\n    $filters: ContactFilterInput\n    $pagination: PaginationInput\n  ) {\n    customers {\n      contacts(filters: $filters, pagination: $pagination) {\n        result {\n          contactType\n          emailAddress\n          firstName\n          fullName\n          lastName\n          mobilePhone\n          supportPortalAccess\n        }\n        totalCount\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n": types.ListContactsDocument,
    "\n  query ListIncidents(\n    $pagination: PaginationInput\n    $filters: IncidentFilterInput\n    $sorting: [IncidentSortingInput]\n  ) {\n    operations {\n      incidents(filters: $filters, pagination: $pagination, sorting: $sorting) {\n        totalCount\n        result {\n          number\n          description\n          priority\n          state\n          severity\n          subject\n          sysCreatedBy\n          sysCreatedOn\n          sysId\n          sysUpdatedBy\n          sysUpdatedOn\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n": types.ListIncidentsDocument,
    "\n  query Nrql($filters: NrqlFilterInput!) {\n    insights {\n      newRelic {\n        nrql(filters: $filters) {\n          result {\n            response\n          }\n          metadata {\n            conversationId\n            message\n            statusCode\n            statusText\n          }\n        }\n      }\n    }\n  }\n": types.NrqlDocument,
    "\n  query GetPowerBiReports($filters: PowerBIFilterInput!) {\n    insights {\n      powerBI(filters: $filters) {\n        result {\n          embedToken\n          embedUrl\n          reportId\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n": types.GetPowerBiReportsDocument,
    "\n  query ListProblems(\n    $pagination: PaginationInput\n    $filters: ProblemFilterInput\n    $sorting: [ProblemSortingInput]\n  ) {\n    operations {\n      problems(filters: $filters, pagination: $pagination, sorting: $sorting) {\n        totalCount\n        result {\n          number\n          description\n          priority\n          state\n          subject\n          sysCreatedBy\n          sysCreatedOn\n          sysId\n          sysUpdatedBy\n          sysUpdatedOn\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n": types.ListProblemsDocument,
    "\n  query ChartMenu($filters: FilterChartMenuInput) {\n    insights {\n      zabbix {\n        chartMenu(filters: $filters) {\n          result {\n            name\n            items {\n              delay\n              description\n              history\n              itemId\n              name\n              units\n            }\n          }\n          metadata {\n            conversationId\n            message\n            statusCode\n            statusText\n          }\n        }\n      }\n    }\n  }\n": types.ChartMenuDocument,
    "\n  query ChartData($filters: FilterChartDataInput) {\n    insights {\n      zabbix {\n        chartData(filters: $filters) {\n          result {\n            items {\n              value\n              datetime\n              itemId\n            }\n            name\n          }\n          metadata {\n            conversationId\n            message\n            statusCode\n            statusText\n          }\n        }\n      }\n    }\n  }\n": types.ChartDataDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation serviceNowAccountUpdate($input: ServiceNowAccountInput!) {\n    serviceNowAccountUpdate(input: $input) {\n      metadata {\n        conversationId\n        message\n        statusCode\n        statusText\n      }\n      result {\n        sysId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation serviceNowAccountUpdate($input: ServiceNowAccountInput!) {\n    serviceNowAccountUpdate(input: $input) {\n      metadata {\n        conversationId\n        message\n        statusCode\n        statusText\n      }\n      result {\n        sysId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation serviceNowCaseCreate($input: CaseCreateInput!) {\n    serviceNowCaseCreate(input: $input) {\n      metadata {\n        conversationId\n        message\n        statusCode\n        statusText\n      }\n      result {\n        sysId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation serviceNowCaseCreate($input: CaseCreateInput!) {\n    serviceNowCaseCreate(input: $input) {\n      metadata {\n        conversationId\n        message\n        statusCode\n        statusText\n      }\n      result {\n        sysId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation serviceNowContactCaseCreate($input: ContactCaseCreateInput!) {\n    serviceNowContactCaseCreate(input: $input) {\n      metadata {\n        conversationId\n        message\n        statusCode\n        statusText\n      }\n      result {\n        sysId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation serviceNowContactCaseCreate($input: ContactCaseCreateInput!) {\n    serviceNowContactCaseCreate(input: $input) {\n      metadata {\n        conversationId\n        message\n        statusCode\n        statusText\n      }\n      result {\n        sysId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query listAccountWithOwnerAndManager($filters: AccountFilterInput!) {\n    customers {\n      accounts(filters: $filters) {\n        totalCount\n        result {\n          accountId\n          accountNumber\n          accountName\n          invoiceDetails {\n            address {\n              city\n              country\n              postalCode\n              street\n            }\n            emailAddress\n            vatNumber\n          }\n          organizationalUnit\n          owner {\n            result {\n              emailAddress\n              firstName\n              lastName\n              mobilePhone\n            }\n          }\n          serviceManager {\n            result {\n              emailAddress\n              firstName\n              lastName\n              mobilePhone\n            }\n          }\n          telephone\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query listAccountWithOwnerAndManager($filters: AccountFilterInput!) {\n    customers {\n      accounts(filters: $filters) {\n        totalCount\n        result {\n          accountId\n          accountNumber\n          accountName\n          invoiceDetails {\n            address {\n              city\n              country\n              postalCode\n              street\n            }\n            emailAddress\n            vatNumber\n          }\n          organizationalUnit\n          owner {\n            result {\n              emailAddress\n              firstName\n              lastName\n              mobilePhone\n            }\n          }\n          serviceManager {\n            result {\n              emailAddress\n              firstName\n              lastName\n              mobilePhone\n            }\n          }\n          telephone\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query listAccountWithServiceNowAccount($filters: AccountFilterInput!) {\n    customers {\n      accounts(filters: $filters) {\n        result {\n          accountNumber\n          serviceNowAccount {\n            result {\n              sysId\n              uSupportInformation\n            }\n          }\n        }\n        totalCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query listAccountWithServiceNowAccount($filters: AccountFilterInput!) {\n    customers {\n      accounts(filters: $filters) {\n        result {\n          accountNumber\n          serviceNowAccount {\n            result {\n              sysId\n              uSupportInformation\n            }\n          }\n        }\n        totalCount\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query listUserAccount {\n    user {\n      accounts {\n        result {\n          accountNumber\n          accountName\n        }\n        metadata {\n          conversationId\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query listUserAccount {\n    user {\n      accounts {\n        result {\n          accountNumber\n          accountName\n        }\n        metadata {\n          conversationId\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListCase(\n    $pagination: PaginationInput\n    $filters: CaseFilterInput\n    $sorting: [CaseSortingInput]\n  ) {\n    operations {\n      cases(filters: $filters, pagination: $pagination, sorting: $sorting) {\n        totalCount\n        result {\n          comments\n          number\n          description\n          priority\n          state\n          subject\n          sysCreatedBy\n          sysCreatedOn\n          sysUpdatedBy\n          sysUpdatedOn\n          workNotes\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListCase(\n    $pagination: PaginationInput\n    $filters: CaseFilterInput\n    $sorting: [CaseSortingInput]\n  ) {\n    operations {\n      cases(filters: $filters, pagination: $pagination, sorting: $sorting) {\n        totalCount\n        result {\n          comments\n          number\n          description\n          priority\n          state\n          subject\n          sysCreatedBy\n          sysCreatedOn\n          sysUpdatedBy\n          sysUpdatedOn\n          workNotes\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListCaseByCI(\n    $pagination: PaginationInput\n    $filters: ConfigurationItemFilterInput\n    $sorting: [CaseSortingInput]\n  ) {\n    operations {\n      configurationItems(filters: $filters) {\n        totalCount\n        result {\n          cases(pagination: $pagination, sorting: $sorting) {\n            result {\n              comments\n              number\n              description\n              state\n              subject\n              sysCreatedBy\n              sysCreatedOn\n              workNotes\n            }\n            totalCount\n          }\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListCaseByCI(\n    $pagination: PaginationInput\n    $filters: ConfigurationItemFilterInput\n    $sorting: [CaseSortingInput]\n  ) {\n    operations {\n      configurationItems(filters: $filters) {\n        totalCount\n        result {\n          cases(pagination: $pagination, sorting: $sorting) {\n            result {\n              comments\n              number\n              description\n              state\n              subject\n              sysCreatedBy\n              sysCreatedOn\n              workNotes\n            }\n            totalCount\n          }\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListChanges(\n    $pagination: PaginationInput\n    $filters: ChangeFilterInput\n    $sorting: [ChangeSortingInput]\n  ) {\n    operations {\n      changes(filters: $filters, pagination: $pagination, sorting: $sorting) {\n        totalCount\n        result {\n          number\n          description\n          impact\n          priority\n          state\n          subject\n          sysCreatedBy\n          sysCreatedOn\n          sysId\n          sysUpdatedBy\n          sysUpdatedOn\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListChanges(\n    $pagination: PaginationInput\n    $filters: ChangeFilterInput\n    $sorting: [ChangeSortingInput]\n  ) {\n    operations {\n      changes(filters: $filters, pagination: $pagination, sorting: $sorting) {\n        totalCount\n        result {\n          number\n          description\n          impact\n          priority\n          state\n          subject\n          sysCreatedBy\n          sysCreatedOn\n          sysId\n          sysUpdatedBy\n          sysUpdatedOn\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListCI(\n    $filters: ConfigurationItemFilterInput\n    $pagination: PaginationInput\n  ) {\n    operations {\n      configurationItems(filters: $filters, pagination: $pagination) {\n        result {\n          cpuCount\n          hostName\n          installStatus\n          ipAddress\n          name\n          os\n          osVersion\n          ram\n          shortDescription\n          sysClassName\n          sysId\n          uLogicalTag\n          uPatchDaySchedule\n          uPatchMonthSchedule\n          uPatchTime\n          uPatchWeekSchedule\n          uPatchingDisabledReason\n          uPatchingEnabled\n          uSupportLevel\n          uVmTechnology\n        }\n        totalCount\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListCI(\n    $filters: ConfigurationItemFilterInput\n    $pagination: PaginationInput\n  ) {\n    operations {\n      configurationItems(filters: $filters, pagination: $pagination) {\n        result {\n          cpuCount\n          hostName\n          installStatus\n          ipAddress\n          name\n          os\n          osVersion\n          ram\n          shortDescription\n          sysClassName\n          sysId\n          uLogicalTag\n          uPatchDaySchedule\n          uPatchMonthSchedule\n          uPatchTime\n          uPatchWeekSchedule\n          uPatchingDisabledReason\n          uPatchingEnabled\n          uSupportLevel\n          uVmTechnology\n        }\n        totalCount\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListContacts(\n    $filters: ContactFilterInput\n    $pagination: PaginationInput\n  ) {\n    customers {\n      contacts(filters: $filters, pagination: $pagination) {\n        result {\n          contactType\n          emailAddress\n          firstName\n          fullName\n          lastName\n          mobilePhone\n          supportPortalAccess\n        }\n        totalCount\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListContacts(\n    $filters: ContactFilterInput\n    $pagination: PaginationInput\n  ) {\n    customers {\n      contacts(filters: $filters, pagination: $pagination) {\n        result {\n          contactType\n          emailAddress\n          firstName\n          fullName\n          lastName\n          mobilePhone\n          supportPortalAccess\n        }\n        totalCount\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListIncidents(\n    $pagination: PaginationInput\n    $filters: IncidentFilterInput\n    $sorting: [IncidentSortingInput]\n  ) {\n    operations {\n      incidents(filters: $filters, pagination: $pagination, sorting: $sorting) {\n        totalCount\n        result {\n          number\n          description\n          priority\n          state\n          severity\n          subject\n          sysCreatedBy\n          sysCreatedOn\n          sysId\n          sysUpdatedBy\n          sysUpdatedOn\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListIncidents(\n    $pagination: PaginationInput\n    $filters: IncidentFilterInput\n    $sorting: [IncidentSortingInput]\n  ) {\n    operations {\n      incidents(filters: $filters, pagination: $pagination, sorting: $sorting) {\n        totalCount\n        result {\n          number\n          description\n          priority\n          state\n          severity\n          subject\n          sysCreatedBy\n          sysCreatedOn\n          sysId\n          sysUpdatedBy\n          sysUpdatedOn\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Nrql($filters: NrqlFilterInput!) {\n    insights {\n      newRelic {\n        nrql(filters: $filters) {\n          result {\n            response\n          }\n          metadata {\n            conversationId\n            message\n            statusCode\n            statusText\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Nrql($filters: NrqlFilterInput!) {\n    insights {\n      newRelic {\n        nrql(filters: $filters) {\n          result {\n            response\n          }\n          metadata {\n            conversationId\n            message\n            statusCode\n            statusText\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetPowerBiReports($filters: PowerBIFilterInput!) {\n    insights {\n      powerBI(filters: $filters) {\n        result {\n          embedToken\n          embedUrl\n          reportId\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetPowerBiReports($filters: PowerBIFilterInput!) {\n    insights {\n      powerBI(filters: $filters) {\n        result {\n          embedToken\n          embedUrl\n          reportId\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListProblems(\n    $pagination: PaginationInput\n    $filters: ProblemFilterInput\n    $sorting: [ProblemSortingInput]\n  ) {\n    operations {\n      problems(filters: $filters, pagination: $pagination, sorting: $sorting) {\n        totalCount\n        result {\n          number\n          description\n          priority\n          state\n          subject\n          sysCreatedBy\n          sysCreatedOn\n          sysId\n          sysUpdatedBy\n          sysUpdatedOn\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ListProblems(\n    $pagination: PaginationInput\n    $filters: ProblemFilterInput\n    $sorting: [ProblemSortingInput]\n  ) {\n    operations {\n      problems(filters: $filters, pagination: $pagination, sorting: $sorting) {\n        totalCount\n        result {\n          number\n          description\n          priority\n          state\n          subject\n          sysCreatedBy\n          sysCreatedOn\n          sysId\n          sysUpdatedBy\n          sysUpdatedOn\n        }\n        metadata {\n          conversationId\n          message\n          statusCode\n          statusText\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ChartMenu($filters: FilterChartMenuInput) {\n    insights {\n      zabbix {\n        chartMenu(filters: $filters) {\n          result {\n            name\n            items {\n              delay\n              description\n              history\n              itemId\n              name\n              units\n            }\n          }\n          metadata {\n            conversationId\n            message\n            statusCode\n            statusText\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ChartMenu($filters: FilterChartMenuInput) {\n    insights {\n      zabbix {\n        chartMenu(filters: $filters) {\n          result {\n            name\n            items {\n              delay\n              description\n              history\n              itemId\n              name\n              units\n            }\n          }\n          metadata {\n            conversationId\n            message\n            statusCode\n            statusText\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ChartData($filters: FilterChartDataInput) {\n    insights {\n      zabbix {\n        chartData(filters: $filters) {\n          result {\n            items {\n              value\n              datetime\n              itemId\n            }\n            name\n          }\n          metadata {\n            conversationId\n            message\n            statusCode\n            statusText\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ChartData($filters: FilterChartDataInput) {\n    insights {\n      zabbix {\n        chartData(filters: $filters) {\n          result {\n            items {\n              value\n              datetime\n              itemId\n            }\n            name\n          }\n          metadata {\n            conversationId\n            message\n            statusCode\n            statusText\n          }\n        }\n      }\n    }\n  }\n"];

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
**/
export function graphql(source: string): unknown;

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;