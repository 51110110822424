import { RouteRecordRaw } from 'vue-router'
import { AUTH_MODULE } from '@/constants/roles'

const accountRoutes: RouteRecordRaw = {
  name: 'Account Information',
  path: 'company-information/account',
  component: () => import('./AccountView.vue'),
  meta: {
    title: 'My Sentia | Account Information',
    requiresAuth: true,
    roles: AUTH_MODULE.HOME,
  },
}

export { accountRoutes }
