import { RouteRecordRaw } from 'vue-router'
import { AUTH_MODULE } from '@/constants/roles'

const licenseReportInsights: RouteRecordRaw = {
  name: 'License Report',
  path: 'insights/license-report',
  component: () => import('./LicenseReport.vue'),
  meta: {
    title: 'My Sentia | License Report',
    requiresAuth: true,
    roles: AUTH_MODULE.REPORTS,
  },
}

export { licenseReportInsights }
