import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/view/partials/BaseLayout.vue'
import { authRoutes } from '@/view/pages/auth/_routes'
import dayjs from 'dayjs'
import { useAuthentication } from '@/store/useAuthentication'
import { storeToRefs } from 'pinia'
import { configurationItemRoutes } from '@/view/pages/assets/_routes'
import { homeRoutes } from '@/view/pages/home/_routes'
import {
  accountRoutes,
  contactsRoutes,
} from '@/view/pages/company-information/_routes'
import { errorRoutes } from '@/view/pages/error/_routes'
import {
  costInsightsRoutes,
  licenseReportInsights,
} from '@/view/pages/insights/_routes'
import { reportsRoutes } from '@/view/pages/reports/_routes'
import { useAccounts } from '@/store/useAccount'

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean
    roles?: Array<string>
    title?: string
  }
}

const routes: Array<RouteRecordRaw> = [
  authRoutes,
  {
    path: '/',
    name: 'Root',
    component: Layout,
    children: [
      accountRoutes,
      reportsRoutes,
      configurationItemRoutes,
      homeRoutes,
      contactsRoutes,
      costInsightsRoutes,
      licenseReportInsights,
    ],
    redirect: '/home',
  },
  errorRoutes,
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const authentication = useAuthentication()
  const { currentUser } = storeToRefs(authentication)
  if (!currentUser.value) {
    await authentication.fetchUser()
  }
  if (authentication.isAuthenticated && currentUser.value) {
    const account = useAccounts()
    account.loadUserAccounts()
  }

  const validSeconds = authentication.accessTokenPayload?.exp - dayjs().unix()
  if (validSeconds <= 300) await authentication.refreshSession()

  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)

  const canNavigate = to.matched.every((route) => {
    return route.meta?.roles
      ? authentication.isAllowed(route.meta?.roles)
      : true
  })

  document.title = to.meta.title || 'My Sentia | Portal'

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (authentication.isAuthenticated) {
      if (canNavigate) {
        next()
      } else {
        if (from.name === '404') {
          next('/')
        } else {
          next('/404')
        }
      }
      return
    }
    next('/auth/login')
  } else {
    next()
  }
})

export default router
